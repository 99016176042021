var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import HTML_STR from './ProjectCard.c.html';
import CSS_STR from './ProjectCard.c.scss';
import { Component, ShadowComponent } from '../../../core';
let ProjectCard = class ProjectCard extends ShadowComponent {
    constructor(data) {
        super();
        this.props = {
            id: '',
            slug: '',
            name: '',
            description: '',
            image: '',
            tags: [],
            demo: '',
            repo: '',
            addedOn: null
        };
        if (data)
            this.setProps(this.sanitize(data));
    }
    sanitize(rawData) {
        const data = Object.create(null);
        Object.keys(rawData).forEach((key) => {
            if (!(key in this.props))
                return;
            data[key] = rawData[key];
        });
        data.addedOn = rawData["added_on"];
        return data;
    }
};
ProjectCard = __decorate([
    Component({
        tagname: 'project-card',
        template: { html: HTML_STR, css: CSS_STR }
    }),
    __metadata("design:paramtypes", [Object])
], ProjectCard);
export default ProjectCard;

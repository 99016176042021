var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import HTML_STR from './FilterBox.c.html';
import CSS_STR from './FilterBox.c.scss';
import { Component, ShadowComponent } from '../../../core';
import { TagButton } from '../components';
import EBuilder from 'ebuilder-js';
let FilterBox = class FilterBox extends ShadowComponent {
    constructor() {
        super(...arguments);
        this.props = {
            activeFilters: new Set(),
            tagButtons: [],
            handleFilterSet: (value) => { }
        };
        this.clear = () => {
            this.props.activeFilters.clear();
            this.props.tagButtons.forEach((tagButton) => tagButton.setIsActive(false));
            this.props.handleFilterSet(this.props.activeFilters);
        };
    }
    setFilters(tagSet, callback) {
        const createTagButton = (name) => EBuilder(new TagButton(name))
            .setListeners(['click', this.handleTagButtonClick.bind(this)])
            .into(EBuilder('li').into(this.$container))
            .element;
        const tagButtons = [...tagSet].map(createTagButton);
        this.setProps({
            tagButtons,
            handleFilterSet: callback
        }, false);
    }
    handleTagButtonClick(event) {
        const tagButton = event.currentTarget; // fix this
        const value = tagButton.props.name;
        tagButton.toggleIsActive();
        this.setActiveFilters(value);
    }
    setActiveFilters(value) {
        this.props.activeFilters.has(value)
            ? this.props.activeFilters.delete(value)
            : this.props.activeFilters.add(value);
        this.props.handleFilterSet(this.props.activeFilters);
    }
};
FilterBox = __decorate([
    Component({
        tagname: 'filter-box',
        template: { html: HTML_STR, css: CSS_STR }
    })
], FilterBox);
export default FilterBox;

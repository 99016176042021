import BaseComponent from './BaseComponent';
export default class LightComponent extends BaseComponent {
    constructor() {
        super();
    }
    select(query) {
        return super.select(query, true);
    }
    selectAll(query) {
        return super.selectAll(query, true);
    }
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import HTML_STR from './ContactBox.c.html';
import CSS_STR from './ContactBox.c.scss';
import { Component, ShadowComponent } from '../../../core';
import { postMessage } from '../../shared/utils';
let ContactBox = class ContactBox extends ShadowComponent {
    connectedCallback() {
        this.$form.addEventListener('submit', (event) => {
            event.preventDefault();
            this.sendMessage();
        });
    }
    sendMessage() {
        return __awaiter(this, void 0, void 0, function* () {
            const messageData = {
                email: this.$email.value,
                subject: this.$subject.value,
                message: this.$message.value
            };
            try {
                const response = yield postMessage(messageData);
                console.log(response);
                if (response.status !== 201)
                    throw new Error('Invalid message');
                this.handleSuccess(response);
            }
            catch (error) {
                this.handleError(error);
            }
        });
    }
    handleSuccess(response) {
        this.$form.reset();
        this.notify(true);
    }
    handleError(error) {
        this.notify(false);
    }
    notify(isSuccess) {
        const notificationTemplate = isSuccess ? this.$success : this.$error;
        this.root.appendChild(notificationTemplate.content.cloneNode(true));
        setTimeout(() => this.select('aside-info').remove(), 3000);
    }
};
ContactBox = __decorate([
    Component({
        tagname: 'contact-box',
        template: { html: HTML_STR, css: CSS_STR }
    })
], ContactBox);
export default ContactBox;

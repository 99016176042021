/**
 * TODO:
 * - Add custom generic animation function support
 * - Add built-in animations
 * - Add support for hooks as a tuple [Function, number] in addition to Promise
 * - Add 'routes: []' option that overwrites decorator routes
 * - DYNAMIC route support???
 * - option to pass data when appending a component: new SomeComponent(data)
 *
 * FIX:
 * - new (component as any)()
 * - proper 404 handling
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export default class Router {
    constructor(options = {}) {
        var _a;
        this.options = {
            appendBefore: false,
            notFoundPath: ''
        };
        this.hooks = {};
        const { container, hooks, appendBefore, notFoundPath } = options;
        const { origin, pathname } = window.location;
        this.container = container || document.querySelector('app-root');
        this.defaultTitle = ((_a = document.querySelector('title')) === null || _a === void 0 ? void 0 : _a.textContent) || '';
        this.options.appendBefore = !!appendBefore;
        this.options.notFoundPath = notFoundPath || '';
        this.baseURL = origin;
        this.setHooks(hooks);
        this.handleURL(this.baseURL + pathname);
        this.listen();
    }
    static setRoute(path, component, options) {
        Router.routes.set(path, {
            component,
            pathname: path,
            title: options.title,
            shortcut: options.shortcut
        });
    }
    setHooks(hooks) {
        Object.assign(this.hooks, hooks);
    }
    handleURL(url) {
        this.to(url, { skip: true });
    }
    listen() {
        // Navigation through <router-link>
        window.addEventListener('navigate', (event) => {
            const { href } = event.detail;
            this.to(href);
        });
        // Key shortcut specified in @Route decorator arguments
        window.addEventListener('keydown', (event) => {
            Router.routes.forEach((route, path) => {
                if (route.shortcut === event.key)
                    this.to(this.baseURL + path);
            });
        });
        // window.addEventListener('popstate', (event: any) => {
        //     console.log('POPSTATE TRIGGERED', event)
        // })
    }
    getPageFromHrefValue(href) {
        const { pathname } = new URL(href);
        const page = Router.routes.get(pathname);
        if (!page)
            throw new Error(JSON.stringify({
                code: 404,
                pathname
            }));
        return page;
    }
    to(href, options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const page = this.getPageFromHrefValue(href);
                const { skip } = options;
                if (page === this.currentPage)
                    return;
                this.performDOM(page, { skip });
                this.performHistory(page, href);
            }
            catch (error) {
                // console.warn(error)
                const errorData = JSON.parse(error.message);
                if (errorData.code === 404)
                    this.handle404(errorData.pathname);
                // console.warn(errorData)
            }
        });
    }
    /**
     * TODO: use decorators for hooks
     */
    performDOM(page, options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const { beforeRemove, afterRemove, beforeAppend, afterAppend } = this.hooks;
            const { component, title } = page;
            const { skip } = options;
            const prevComponent = this.currentComponent;
            const nextComponent = new component();
            const performRemove = () => __awaiter(this, void 0, void 0, function* () {
                if (!prevComponent)
                    return;
                if (beforeRemove && !skip)
                    yield beforeRemove(prevComponent);
                this.container.removeChild(prevComponent);
                if (afterRemove && !skip)
                    yield afterRemove(prevComponent);
            });
            const performAppend = () => __awaiter(this, void 0, void 0, function* () {
                if (beforeAppend && !skip)
                    yield beforeAppend(nextComponent);
                this.container.appendChild(nextComponent);
                if (afterAppend && !skip)
                    yield afterAppend(nextComponent);
            });
            const performActions = () => __awaiter(this, void 0, void 0, function* () {
                const actions = this.options.appendBefore
                    ? [performAppend, performRemove]
                    : [performRemove, performAppend];
                for (const action of actions)
                    yield action();
            });
            this.setCurrentState({ page, nextComponent, title });
            yield performActions();
        });
    }
    performHistory(page, href) {
        window.history.pushState(null, page.title || '', href);
        window.dispatchEvent(new CustomEvent('pagechange', { detail: page }));
    }
    setCurrentState({ page, nextComponent, title }) {
        this.setPageTitle(title);
        this.currentPage = page;
        this.currentComponent = nextComponent;
    }
    setPageTitle(value) {
        const titleElement = document.querySelector('title');
        if (!titleElement)
            return;
        titleElement.textContent = value || this.defaultTitle;
    }
    handle404(requestedPath) {
        if (!this.options.notFoundPath)
            return;
        this.to(window.location.origin + this.options.notFoundPath);
    }
}
Router.routes = new Map();

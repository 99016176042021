var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var ProjectList_1;
import HTML_STR from './ProjectList.c.html';
import CSS_STR from './ProjectList.c.scss';
import EBuilder from 'ebuilder-js';
import Check from '../../shared/Check';
import { Component, ShadowComponent } from '../../../core';
import { ProjectCard } from '../components';
import { app } from '../../';
let ProjectList = ProjectList_1 = class ProjectList extends ShadowComponent {
    constructor() {
        super(...arguments);
        this.props = {
            projects: [],
            currentDisplay: []
        };
        this.handleFilter = (filters) => {
            const filtered = !filters.size
                ? this.props.projects
                : this.props.projects.filter((project) => {
                    return [...filters].reduce((acc, curr) => {
                        return acc && project.tags.includes(curr);
                    }, true);
                });
            if (Check.areEqualArrays(this.props.currentDisplay, filtered))
                return;
            this.setProps({
                currentDisplay: filtered
            }, false);
            this.applyFilters();
        };
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            // window.addEventListener('projectDataLoad', this.handleDataLoad.bind(this))
            // if (ProjectList.loadedData.length)
            //     window.dispatchEvent(new CustomEvent('projectDataLoad', { detail: ProjectList.loadedData }))
            const projectData = yield app.projectData;
            // try {
            this.handleDataLoad({ detail: projectData });
            // } catch {
            //     await pause(50)
            //     this.connectedCallback()
            //     console.log('iteration')
            // }
        });
    }
    handleDataLoad(event) {
        const data = event.detail.filter((project) => !project['is_hidden']);
        const tagList = data.reduce((acc, curr) => [...acc, ...curr.tags], data[0].tags);
        const tagSet = new Set(tagList);
        ProjectList_1.loadedData = data;
        this.setProps({
            projects: data,
            currentDisplay: data
        }, false);
        this.appendProjectCards();
        this.appendTagFilters(tagSet);
    }
    applyFilters() {
        const items = [...this.$list.childNodes]
            .filter((node) => node instanceof HTMLLIElement)
            .reverse()
            .map((item, i) => EBuilder(item)
            .setStyle({ transition: `opacity 400ms ${50 * i}ms` })
            .setStyle({ opacity: 0 }));
        const duration = 50 * items.length;
        setTimeout(() => {
            while (this.$list.firstElementChild)
                this.$list.removeChild(this.$list.firstElementChild);
            this.appendProjectCards();
        }, duration);
    }
    appendProjectCards() {
        const createProjectCard = (project, i) => EBuilder('li')
            .set({
            children: new ProjectCard(project),
            style: {
                opacity: 0,
                transition: `opacity 400ms ${50 * i}ms`
            }
        })
            .setStyle({ 'opacity@timeout:0': 1 });
        const display = !!this.props.currentDisplay.length
            ? this.props.currentDisplay.map(createProjectCard)
            : this.$empty.content.cloneNode(true);
        EBuilder(this.$list).setChildren(display);
        if (this.select('.clear-button')) {
            this.select('.clear-button').onclick = this.$filterBox.clear;
        }
    }
    appendTagFilters(tagSet) {
        this.$filterBox.setFilters(tagSet, this.handleFilter);
    }
};
ProjectList.loadedData = [];
ProjectList = ProjectList_1 = __decorate([
    Component({
        tagname: 'project-list',
        template: { html: HTML_STR, css: CSS_STR }
    })
], ProjectList);
export default ProjectList;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import HTML_STR from './AppLoader.c.html';
import CSS_STR from './AppLoader.c.scss';
import { Component, ShadowComponent } from '../../../core';
import LoaderTask from './LoaderTask';
let AppLoader = class AppLoader extends ShadowComponent {
    constructor(tasks, callback) {
        super();
        this.props = {
            tasks: []
        };
        this.props.tasks = tasks.map((task) => new LoaderTask(task));
        if (callback)
            this.props.callback = callback;
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            this.compose(this.$messages)
                .setChildren(this.props.tasks.map((task) => task.EBO || ''));
            const data = yield this.fulfillTasks();
            if (this.props.callback)
                this.props.callback(data);
            this.dismiss();
        });
    }
    fulfillTasks() {
        return Promise.all(this.props.tasks.map((task) => task.promise));
    }
    dismiss() {
        this.classList.add('out');
        // Remove node after fixed duration
        const duration = 1000; // or this.props.transitionDuration if choice is given
        setTimeout(() => this.remove(), duration);
        // Remove node after transition ends -- not working if tasks are immediate (synchronous)
        // this.ontransitionend = (event: TransitionEvent) => {
        //     if (!(event.propertyName === 'opacity' && this.getComputedValue('opacity') === '0')) return
        //     this.parentNode!.removeChild(this)
        // }
    }
};
AppLoader = __decorate([
    Component({
        tagname: 'app-loader',
        template: { html: HTML_STR, css: CSS_STR }
    }),
    __metadata("design:paramtypes", [Array, Function])
], AppLoader);
export default AppLoader;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import EBuilder from 'ebuilder-js';
export const loadProjectData = () => __awaiter(void 0, void 0, void 0, function* () {
    const URL = __API_BASE_URL__ + '/api/v1/projects/';
    const data = yield (yield fetch(URL)).json();
    // choose between event / return
    window.dispatchEvent(new CustomEvent('projectDataLoad', { detail: data }));
    return data;
});
export const postMessage = (messageData) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield fetch(__API_BASE_URL__ + '/contact/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify(messageData),
        });
        if (!response.ok && response.status === 400)
            throw new Error(response.statusText);
        return { status: response.status };
    }
    catch (error) {
        console.warn(error);
        return { status: 400 };
    }
});
export const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export const removeTargetAfterFadeout = (event) => {
    const target = event.target;
    if (event.propertyName !== 'opacity' || window.getComputedStyle(target).opacity !== '0')
        return;
    target.parentNode.removeChild(target);
};
export const getRouterHooks = () => {
    const container = document.getElementById('routing');
    const duration = 800;
    const loadingBar = EBuilder('div').setStyle({
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: '5000',
        width: '100%',
        height: '.4rem',
        background: 'var(--main-color)',
        transform: 'scaleX(0)',
        transformOrigin: 'left',
        transition: `transform ${duration}ms`,
    });
    const beforeRemove = (element) => {
        element.classList.add('out');
        loadingBar
            .setStyle({
            transform: 'scaleX(0)'
        })
            .set({
            'style@timeout:0': {
                transform: 'scaleX(1)',
                transformOrigin: 'left'
            }
        })
            .into(container);
        return pause(duration);
    };
    const beforeAppend = (element) => {
        element.classList.add('in');
        return Promise.resolve();
    };
    const afterAppend = (element) => {
        const newDuration = duration;
        setTimeout(() => {
            element.classList.remove('in');
            loadingBar.setStyle({
                transformOrigin: 'right',
                transform: 'scaleX(0)',
                transitionDuration: `${newDuration}ms`
            });
        }, 50);
        setTimeout(loadingBar.out, newDuration);
        return pause(newDuration);
    };
    return {
        beforeRemove,
        beforeAppend,
        afterAppend,
    };
};

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import HTML_STR from './MainHeader.c.html';
import CSS_STR from './MainHeader.c.scss';
import { Component, ShadowComponent } from '../../../core';
let MainHeader = class MainHeader extends ShadowComponent {
};
MainHeader = __decorate([
    Component({
        tagname: 'main-header',
        template: { html: HTML_STR, css: CSS_STR }
    })
], MainHeader);
export default MainHeader;

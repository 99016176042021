import BaseComponent from '../components/BaseComponent';
/**
 * Component class decorator: automatically generates the full html string template
 * (including stylesheet) from the separate files, add the optionals attributes and
 * listeners then define the component.
 */
export default function Component(options) {
    return (target) => {
        const connectedCallbackOrigin = target.prototype.connectedCallback;
        const { tagname, template, _extends, attributes, listeners } = options;
        if (target.prototype instanceof BaseComponent && template) {
            const styleStr = template.css ? `<style>\n${template.css}\n</style>\n` : '';
            const templateStr = styleStr + template.html;
            Object.defineProperty(target, 'TEMPLATE_STR', { value: templateStr });
        }
        target.prototype.connectedCallback = function () {
            if (attributes)
                Object.keys(attributes).forEach((name) => this.setAttribute(name, attributes[name]));
            if (listeners)
                listeners.forEach(([event, handler, options]) => this.addEventListener(event, handler, options));
            if (target.prototype instanceof BaseComponent) {
                this.render();
                this.setRefList();
            }
            if (connectedCallbackOrigin)
                connectedCallbackOrigin.apply(this);
        };
        // Define element
        const defineOptions = [tagname, target];
        if (_extends)
            defineOptions.push({ extends: _extends });
        customElements.define(...defineOptions);
    };
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import HTML_STR from './TagButton.c.html';
import CSS_STR from './TagButton.c.scss';
import { Component, ShadowComponent } from '../../../core';
let TagButton = class TagButton extends ShadowComponent {
    constructor(name) {
        super();
        this.props = {
            name: '',
            isActive: false
        };
        if (name)
            this.props.name = name;
    }
    connectedCallback() {
        if (!!this.props.name)
            return;
        this.setProps({
            name: this.htmlContent
        });
    }
    toggleIsActive() {
        this.setIsActive(!this.props.isActive);
    }
    setIsActive(isActive) {
        this.props.isActive = isActive;
        this.props.isActive
            ? this.$button.classList.add('is-active')
            : this.$button.classList.remove('is-active');
    }
};
TagButton = __decorate([
    Component({
        tagname: 'tag-button',
        template: { html: HTML_STR, css: CSS_STR }
    }),
    __metadata("design:paramtypes", [String])
], TagButton);
export default TagButton;

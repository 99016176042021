var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import handlebars from 'handlebars';
import Build from 'ebuilder-js';
export default class BaseComponent extends HTMLElement {
    constructor(isShadow = false) {
        super();
        this.props = {};
        this.root = isShadow ? this.attachShadow({ mode: 'open' }) : this;
        const rawTemplate = this.constructor.TEMPLATE_STR;
        const processedTemplate = handlebars.compile(rawTemplate, { noEscape: true })(this.props);
        this.htmlContent = this.innerHTML;
        this.root.appendChild(Build('template')
            .setContent(processedTemplate)
            .element.content.cloneNode(true));
        // this.manageLinkRoutes()
    }
    parse(html) {
        const replaceMatchWithPropValue = (source, propName) => {
            return this.props[propName] ? this.props[propName] : source;
        };
        return html.replace(/{{\s?([\w]+)\s?}}/g, replaceMatchWithPropValue);
    }
    select(query, fromLight) {
        return fromLight ? this.querySelector(query) : this.root.querySelector(query);
    }
    selectAll(query, fromLight) {
        return fromLight ? this.querySelectorAll(query) : this.root.querySelectorAll(query);
    }
    compose(source) {
        return source ? Build(source) : Object.assign(Object.assign({}, Build(this)), { wait: function (ms) {
                return __awaiter(this, void 0, void 0, function* () {
                    const pause = () => new Promise(res => setTimeout(() => res(this), ms));
                    yield pause();
                    return this;
                });
            } });
    }
    setProp(propName, value, render = true) {
        this.props[propName] = value;
        if (render)
            this.render();
    }
    setProps(input, render = true) {
        Object.assign(this.props, input);
        if (render)
            this.render();
    }
    setRefList() {
        const refList = this.selectAll('[data-ref]');
        refList.forEach(this.setRef.bind(this));
    }
    setRef(refElement) {
        const refName = '$' + refElement.getAttribute('data-ref');
        // refElement.removeAttribute('data-ref', null)
        this[refName] = refElement;
    }
    attributeChangedCallback(attr, oldValue, newValue) {
        if (newValue === oldValue)
            return;
        if (attr.split('data-')[1] in this.props) {
            const name = attr.replace(/^data-/, '');
            this.setProp(name, newValue);
        }
    }
    getComputedValue(name) {
        return window.getComputedStyle(this)[name];
    }
    // manageLinkRoutes() {
    //     const links = this.selectAll('a[data-link]')
    //     const isSpecialClick = (event: MouseEvent) => (
    //         event.ctrlKey
    //         || event.metaKey
    //         || event.shiftKey
    //         || (event.button && event.button === 1)
    //     )
    //     const handleClick = (event: Event) => {
    //         if (isSpecialClick(event as MouseEvent)) return
    //         const link = event.currentTarget as HTMLAnchorElement
    //         const href = link.href
    //         if (!href || link.getAttribute('href')!.match(/^#/)) return
    //         event.preventDefault()
    //         // // solution 1: use unique router instance, which must be instanciated in the core
    //         // import { router } from '../routing'
    //         // router.to(href)
    //         // solution 2: use custom events
    //         window.dispatchEvent(new CustomEvent('navigate', { detail: {
    //             href
    //         }}))
    //     }
    //     const addClickListener = (target: Element) => target.addEventListener('click', handleClick)
    //     links.forEach(addClickListener)
    // }
    /**
     * TODO: save current tree before render to avoid erasing of computed elements
     */
    render() {
        const rawTemplate = this.constructor.TEMPLATE_STR;
        const processedTemplate = handlebars.compile(rawTemplate, { noEscape: true })(this.props);
        // not the best, but works for now
        this.root.innerHTML = processedTemplate;
        this.setRefList();
        // this.manageLinkRoutes()
        this.dispatchEvent(new CustomEvent('rendered'));
    }
}
BaseComponent.TEMPLATE_STR = '';

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Component } from "../decorators";
import LightComponent from './LightComponent';
let RouteLink = class RouteLink extends LightComponent {
    constructor() {
        super();
        this.props = {
            content: '',
            to: ''
        };
        this.props.content = this.htmlContent;
        this.props.to = this.getAttribute('to') || '';
        this.$a = this.select('a');
        this.listen();
    }
    static get observedAttributes() {
        return ['to'];
    }
    attributeChangedCallback(name, oldValue, newValue) {
        if (newValue === oldValue)
            return;
        if (name === 'to') {
            this.setTo(newValue);
        }
    }
    listen() {
        window.addEventListener('pagechange', (event) => {
            const { pathname } = event.detail;
            pathname === this.props.to
                ? this.setAttribute('is-active', '')
                : this.removeAttribute('is-active');
        });
    }
    setTo(value) {
        this.props.to = value;
        this.$a.setAttribute('href', value);
        this.attachClickEvent();
        // this.onclick = (function(this: RouteLink) { console.log(this.props.to); return false; }).bind(this)
    }
    attachClickEvent() {
        const isSpecialClick = (event) => (event.ctrlKey
            || event.metaKey
            || event.shiftKey
            || (event.button && event.button === 1));
        const handleClick = (event) => {
            if (isSpecialClick(event))
                return;
            if (!this.$a.href)
                return;
            event.preventDefault();
            // // solution 1: use unique router instance, which must be instanciated in the core
            // import { router } from '../routing'
            // router.to(href)
            // solution 2: use custom events
            window.dispatchEvent(new CustomEvent('navigate', { detail: {
                    href: this.$a.href
                } }));
        };
        this.$a.addEventListener('click', (event) => event.preventDefault());
        this.addEventListener('click', handleClick, true);
    }
};
RouteLink = __decorate([
    Component({
        tagname: 'route-link',
        template: {
            html: '<a href="{{to}}" data-link>{{content}}</a>'
        }
    }),
    __metadata("design:paramtypes", [])
], RouteLink);
export default RouteLink;
